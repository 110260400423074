<template>
  <div class="welcome-area welcome-area--success">
    <img class="welcome-area__logo" src="@/assets/img/success-icon.svg" alt="">
    <div class="welcome-area__title">{{ $t('snacksSuccess.ty') }},<br> {{ $t('snacksSuccess.order') }}</div>
    <div class="welcome-area__text">{{ $t('snacksSuccess.text') }}</div>
    <div class="welcome-area__holder">
      <router-link :to="{name: 'home', params: {hash: $route.params.hash}}" class="btn btn--default">
        {{ $t('snacksSuccess.thanks') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnackSuccess',
}
</script>
